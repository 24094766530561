import React, { useState } from 'react';
import axios from 'axios';
import { Analytics } from "@vercel/analytics/react"

const DejaTunes = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data } = await axios({
        method: 'get',
        url: `https://dejatunes-900817204107.us-west2.run.app?q=${encodeURIComponent(searchTerm)}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        withCredentials: false
      });
      setSearchResults(data.data);
    } catch (err) {
      setError('An error occurred while fetching data. Please try again.');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const ResultSection = ({ title, items, itemKey }) => {
    if (!items || items.length === 0) return null;
    return (
      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>{title}</h2>
        {items.map((item, index) => (
          <div key={index} style={styles.resultItem}>
            <h3 style={styles.itemTitle}>{item[itemKey]}</h3>
            <div style={styles.videoContainer}>
              <iframe
                src={item.url.replace('watch?v=', 'v/')}
                style={styles.video}
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
            </div>
            <p style={styles.notes}>{item.notes}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>DejaTunes</h1>
      <h2 style={styles.subTitle}>The Samples Behind the Song</h2>
      <div style={styles.searchContainer}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for a song"
          style={styles.input}
        />
        <button onClick={handleSearch} style={styles.button} disabled={isLoading}>
          {isLoading ? 'Searching...' : 'Search'}
        </button>
      </div>

      {error && <p style={styles.error}>{error}</p>}

      {searchResults && (
        <div style={styles.results}>
          <h2 style={styles.resultTitle}>{searchResults.title}</h2>
          <ResultSection title="Songs Sampled" items={searchResults.song_samples} itemKey="song_name"/>
          <ResultSection title="Songs That Sampled" items={searchResults.songs_that_sampled} itemKey="song_name" />
          <ResultSection title="Similar Songs & Artists" items={searchResults.similar_songs_artists} itemKey="name" />
        </div>
      )}
      <Analytics />
    </div>
  );
};

const styles = {
  container: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '40px 20px',
    textAlign: 'center',
  },
  title: {
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '20px',
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '40px',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '40px',
  },
  input: {
    width: '300px',
    padding: '12px 20px',
    fontSize: '16px',
    border: '2px solid #ddd',
    borderRadius: '25px 0 0 25px',
    outline: 'none',
  },
  button: {
    padding: '12px 24px',
    fontSize: '16px',
    backgroundColor: '#FF0000',
    color: 'white',
    border: 'none',
    borderRadius: '0 25px 25px 0',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  results: {
    textAlign: 'left',
  },
  resultTitle: {
    fontSize: '48px',
    color: '#333',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '30px',
  },
  sectionTitle: {
    fontSize: '32px',
    color: '#FF0000',
    marginBottom: '20px',
    borderBottom: '2px solid #FF0000',
    paddingBottom: '5px',
    display: 'inline-block',
  },
  resultItem: {
    marginBottom: '15px',
    backgroundColor: 'grey',
    padding: '20px',
    borderRadius: '8px',
  },
  itemTitle: {
    fontSize: '18px',
    color: 'white',
    marginBottom: '10px',
  },
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    marginBottom: '15px',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '4px',
  },
  notes: {
    fontSize: '18px',
    color: 'white',
  },
  error: {
    color: 'red',
    marginBottom: '20px',
  },
};

export default DejaTunes;